<template>
	<!--公共页面 bug详情 --提出者-->
<div>	
	<GModule class="container">
		<GTitle name="BUG详情" style="margin: 20px;"></GTitle>
		<div class="bug-box" v-if="info">
			<a-form
				class="need-box"
			    :model="info"
			    :label-col="{ span: 6}"
			    :wrapper-col="{ span: 14 }">
				<a-form-item label="BUGID">
				    <a-input :value="info.id" disabled/>
				</a-form-item>
				<a-form-item label="BUG等级">
				    <a-input :value="info.level_text" disabled/>
				</a-form-item>
				<a-form-item label="处理状态">
				    <a-input :value="info.status_text" disabled/>
				</a-form-item>
				<a-form-item label="提出人">
				    <a-input :value="info.createinfo.nickname" disabled/>
				</a-form-item>
				<a-form-item label="指派给">
					<p style="display: inline;margin-right: 20px;" v-for="(itm,ind) in info.dev_info" :key="ind">{{itm.nickname}}</p>
				</a-form-item>
				<a-form-item label="提出时间">
				    <a-input :value="info.createtime_text" disabled/>
				</a-form-item>
				<a-form-item label="BUG描述">
				    <a-textarea style="height: 200px;" :value="info.describe" disabled/>
				</a-form-item>
				<a-form-item label="图片">
				    <div class="img-scroll" @click="openSwiper(info.images)">
				    	<a-image :preview="false" :width="90" :src="itm" v-for="(itm,ind) in info.images" :key="ind"/>
				    </div>
				</a-form-item>
				<a-form-item label="需求模块">
				    <a-breadcrumb separator=">" style="margin-bottom: 14px;">
				        <a-breadcrumb-item v-for="(itm,ind) in info.demand.modulars" :key="ind">{{itm}}</a-breadcrumb-item>
				     </a-breadcrumb>
				</a-form-item>
				<a-form-item label="需求描述">
				    <a-textarea style="height: 200px;" :value="info.demand.describe" disabled/>
				</a-form-item>
				<a-form-item label="参数/逻辑说明">
				    <a-textarea style="height: 200px;" :value="info.demand.explain" disabled/>
				</a-form-item>
				<a-form-item label="需求图片">
				    <div class="img-scroll" @click="openSwiper(info.imgArr)">
				    	<a-image :preview="false" :width="90" :src="itm" v-for="(itm,ind) in info.imgArr" :key="ind"/>
				    </div>
				</a-form-item>
				<a-form-item label="相关用例">
				    <div class="case-box">
				    	<a-tabs v-model:activeKey="activeKey">
				    		<a-tab-pane 
				    			v-for="(i,index) in caseList" 
				    			:key="index" 
				    			:tab="'用例'+i.id">
				    			<p>1. 用例摘要：</p>
				    			<p>&nbsp;&nbsp;&nbsp;&nbsp;{{i.describe}}</p>
				    			<p>2. 前置条件：</p>
				    			<p>&nbsp;&nbsp;&nbsp;&nbsp;{{i.describe}}</p>
				    			<p>3. 操作步骤：</p>
				    			<p v-for="(sitm,sindex) in i.steps" :key="sindex">
				    				&nbsp;&nbsp;&nbsp;&nbsp;({{sitm.index}}). {{sitm.content}}
				    			</p>
				    		</a-tab-pane>
				    	</a-tabs>
				    </div>
				</a-form-item>
				<a-form-item  :wrapper-col="{ offset:10 }" v-if="state">
				  <a-button class="btn clo6" @click="goBack()">返回</a-button>
				  <template v-if="authority">
				  	<a-popconfirm
				  		v-if="info.status === 'unresolved'"
				  		title="确认是否解决?"
				  		ok-text="确定"
				  		cancel-text="取消"
				  		@confirm="confirm(info.id)">
				  		<a-button class="btn clo1">确认解决</a-button>
				  	</a-popconfirm>
					<a-button class="btn clo6" v-else>已解决</a-button>
				  </template>
				  <template v-else>
				  	<a-popconfirm
				  		v-if="info.status === 'wait' || info.status === 'feedback'"
				  		title="确认是否解决?"
				  		ok-text="确定"
				  		cancel-text="取消"
				  		@confirm="confirm(info.id)">
				  		<a-button class="btn clo1">确认解决</a-button>
				  	</a-popconfirm>
				  	<a-button class="btn clo6" v-else>确认解决</a-button>
				  </template>
				  <a-button class="btn clo3" @click="msgClick()" v-if="authority  && info.status == 'unresolved'">反馈</a-button>
				  <!-- <a-popconfirm
				  	title="确认是否删除?"
				  	ok-text="确定"
				  	cancel-text="取消"
				  	@confirm="delBtn()">
				  	<a-button type="danger">删除</a-button>
				  </a-popconfirm>  -->
				</a-form-item>
				<a-form-item  :wrapper-col="{ offset:10 }" v-else>
				  <a-button class="btn clo6" @click="goBack()">返回</a-button>
				</a-form-item>
			</a-form>
			<div class="remark">
				<p>备注：</p>
				<div v-if="info&&info.remark_info">
					<p style="color: #333333;word-break: break-all;" 
						v-for="(ritem,rindex) in info.remark_info" 
						:key="rindex">{{ritem.nickname}}备注：<span style="color: #999999;" >{{ritem.content}}</span></p>
				</div>
			</div>
		</div>
		<a-modal v-model:visible="isUpdate" title="反馈" @ok="updateOk()">
			<a-textarea placeholder="反馈信息" style="height: 150px;" v-model:value="msg" />
		</a-modal>
	</GModule>
</div>	
</template>

<script>
export default{
	data(){
		return{
			caseList:[], 
			activeKey:0,
			id:0, // 详情id
			info:null ,// 详情
			isUpdate:0,
			msg:'',
			authority:null,
			state:0
		}
	},
	created() {
		this.id = this.$route.query.id || 0
		this.authority = this.$route.query.authority || null
		this.state = this.$route.query.state || 0
		console.log('this.authority',this.authority)
		if(this.id){
			this.getDetail()
		}
	},
	methods:{
		openSwiper(arr){
			// 弹出图片列表
			this.$store.commit('setSwiperArr',arr)
			this.$store.commit('setSwiper',1)
		},
		async getDetail(){
			let res = await this.$utils.api.post({
				url:'/bug/getRow',
				data:{
					ids:this.id
				},
				result:1
			})
			if(res.code == 1){
				if(res.data.demand.images != null && res.data.demand.images.length > 0 ){
					res.data.imgArr = res.data.demand.images.split(',')
				}else{
					res.data.imgArr = []
				}
				this.info = res.data
				this.caseList = res.data.cases
			}
		},
		async delBtn(){
			  // 删除
			  let res = await this.$utils.api.post({
				url:'/bug/del',
				data:{
					ids:this.id
				},
				result:1
			  })
			  if(res.code == 1){
				this.$utils.msgSuc('删除成功')
				this.$router.back()
			  }
		},
		goBack(){
			this.$router.back()
		},
		async confirm(id){
			  // 确认解决
			  let url = ''
			  let status = ''
			  let status_text = ''
			  if(this.authority){ // 开发
				  url='/bug/solve'
				  status = 'wait'
				  status_text= '待确认'
			  }else{// 其他身份
				   url='/bug/confirm'
				   status = 'resolved'
				   status_text= '已解决'
			  }
			  let res = await this.$utils.api.post({ 
				url:url,
				data:{
					ids:id
				},
				result:1
			  })
			  if(res.code == 1){
				this.$utils.msgSuc('确认解决成功')
				this.info.status = status
				this.info.status_text = status_text
			  }
		},
		msgClick(){
			this.msg = ''
			this.isUpdate= 1
		},
		async updateOk(){
			// 反馈
			if(this.msg == ''){
				this.$utils.msgWar('请输入反馈意见')
				return
			}
			let res = await this.$utils.api.post({
				url:'/bug/feedback',
				data:{
					ids:this.id,
					msg:this.msg
				},
				result:1
			})
			if(res.code == 1){
				this.$utils.msgSuc('反馈成功')
				this.isUpdate= 0
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.container{
	margin: 20px;
	padding: 0;
	overflow-y: auto;
	.bug-box{
		display: flex;
		justify-content: space-around;
		.remark{
			flex: 1.2;
			border-left: 1px solid #ccc;
			padding: 0 10px;
			margin-bottom: 70px;
		}
		.need-box{
			flex: 2.8;
			.btn{
				margin: 30px 10px;
			}
			.img-scroll{
				height: 90px;
				overflow-x: auto;
				overflow-y: hidden;
				display: flex;
				align-items: center;
				&::-webkit-scrollbar {
					height: 0px; // 控制滚动条
				}
				&::-webkit-scrollbar-thumb{
					background-color: #a5ddff; // 滚动条颜色
				}
				&:hover{
					&::-webkit-scrollbar {
						height: 8px;
					
					}
				}
			}
		}
	}
}
</style>